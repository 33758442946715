.shu-legacy {
  .section {
    float: left;
    width: 100%;
    position: relative;
    padding: 0;
  }

  .section .row [class*="column"] + [class*="column"]:last-child {
    float: left;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
  }

  input {
    font-size: 16px;
  }

  @media only screen and (min-width: 768px) {
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }

  p {
    margin-bottom: 20px;
  }

  strong {
    font-weight: 600;
  }

  p a.button {
    font-size: 8px;
    float: none;
  }

  a {
    color: #b70d50;
    text-decoration: none !important;
  }

  @media only screen and (min-width: 768px) {
    a:hover {
      color: #000;
    }
  }

  a a:link a:visited {
    color: inherit;
  }

  body {
    min-width: 320px;
  }

  .row {
    max-width: 1120px;
  }

  a {
    color: #b70d50;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  input.button a,
  p.button a,
  span.button a {
    float: left;
    padding: 16px 18px 14px 18px;
    background: #910a3f;
    color: #FFFFFFFFFFFF;
    height: auto;
    position: relative;
    text-decoration: none;
    transition: background 0.4s;
    margin-right: 20px;
  }

  input.button a:hover,
  p.button a:hover,
  span.button a:hover {
    background: #6b082f;
    text-decoration: none;
  }

  input.button a:last-child,
  p.button a:last-child,
  span.button a:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 767px) {
    #content p.button a {
      margin-bottom: 20px;
    }
  }

  body {
    background: #FFFFFFFFFFFF;
  }

  #content {
    position: relative;
    z-index: 50;
    float: left;
    width: 100%;
    overflow: hidden;
  }

  @media only screen and (max-width: 1023px) {
    .columns {
      padding-left: 25px;
      padding-right: 25px;
    }

    .columns .columns {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  }

  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eaeaea;
  }

  .section.form .large-10 > .row,
  .section.form .large-12 > .row {
    margin-bottom: 20px;
  }

  .section.form .large-10 .row .medium-3,
  .section.form .large-12 .row .medium-3 {
    padding-right: 0;
  }

  .section.form .large-10 .row .medium-9,
  .section.form .large-12 .row .medium-9 {
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    .section.form .large-10 .row .columns,
    .section.form .large-12 .row .columns {
      padding: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .section.form .large-10,
    .section.form .large-12 {
      padding: 0;
    }

    .section.form .large-10 > .row,
    .section.form .large-12 > .row {
      margin: 0;
    }
  }

  .talks {
    float: left;
    width: 100%;
    background: #f5f5f5;
    padding: 40px;
    margin-bottom: 30px;
  }

  .talks > div {
    float: left;
    width: 47%;
  }

  @media only screen and (max-width: 767px) {
    .talks > div {
      width: 100%;
    }
  }

  .talks > div a {
    background: #b70d50;
    color: #FFFFFFFFFFFF;
    float: left;
    width: 100%;
    padding: 16px 12px;
    text-align: center;
    border: 1px solid #760029;
    position: relative;
  }

  .talks > div a:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 12px;
    right: 20px;
    background: url(https://www.shu.ac.uk/assets/images/icons/accordion-arrow-white.png)
      no-repeat center center;
    top: 50%;
    margin-top: -6px;
  }

  .talks > div:last-child {
    float: right;
  }

  @media only screen and (max-width: 767px) {
    .talks > div:last-child:not(.course-select) {
      margin-top: 20px;
    }
  }

  .visually-hidden {
    position: absolute !important;
    padding: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .is-invisible {
    visibility: hidden;
  }

  @media only screen and (max-width: 767.9999px) {
    .is-hidden-mobile {
      display: none;
      visibility: hidden;
    }
  }

  .is-overflow-hidden {
    overflow: hidden !important;
  }

  .c-header.section {
    position: static;
  }

  .c-header__inner-wrapper {
    position: static !important;
  }

  .c-header__inner-wrapper:after,
  .c-header__inner-wrapper:before {
    content: " ";
    display: table;
  }

  .c-header__inner-wrapper:after {
    clear: both;
  }

  @media only screen and (max-width: 767px) {
    .c-header__inner-wrapper {
      padding: 0 !important;
    }
  }

  .c-header__top-row {
    background-color: #FFFFFFFFFFFF;
    border-bottom: 2px solid #e7e7e7;
  }

  @media only screen and (min-width: 768px) {
    .c-header__top-row {
      background-color: #f6f6f6;
    }
  }

  // .c-logo {
  //   display: block;
  //   float: left;
  //   padding: 11px 0 9px 15px;
  // }

  // @media only screen and (min-width: 768px) {
  //   .c-logo {
  //     padding: 22px 15px 18px 0;
  //   }
  // }

  // .c-logo__img {
  //   display: block;
  //   height: 40px;
  //   width: 75px;
  // }

  // @media only screen and (min-width: 768px) {
  //   .c-logo__img {
  //     height: 70px;
  //     width: 130px;
  //   }
  // }

  .c-header__hamburger-btn,
  .c-header__prospectus-btn {
    background: 0 0;
    border: none;
    padding: 0;
    position: relative;
    float: left;
    display: block;
    height: 60px;
    width: 60px;
    border-left: 2px solid #e7e7e7;
    border-radius: 0;
    cursor: pointer;
  }

  .c-header__mobile-buttons {
    float: right;
  }

  .c-header__mobile-buttons:after,
  .c-header__mobile-buttons:before {
    content: " ";
    display: table;
  }

  .c-header__mobile-buttons:after {
    clear: both;
  }

  @media only screen and (min-width: 768px) {
    .c-header__mobile-buttons {
      display: none;
    }
  }

  .c-header__prospectus-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    height: 20px;
    width: 24px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/book.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .c-header__prospectus-btn-count {
    position: absolute;
    top: -6px;
    right: -7px;
    padding: 5px;
    background-color: #ba0046;
    border-radius: 8px;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 0.7;
  }

  .c-header__hamburger-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 22px;
  }

  .c-header__hamburger-btn-line {
    display: block;
    height: 3px;
    width: 100%;
    margin-bottom: 5px;
    background-color: #777777;
  }

  .c-header__hamburger-btn-line:last-child {
    margin-bottom: 0;
  }

  .c-header__utilities {
    float: right;
    padding: 14px 0 15px;
  }

  .c-header__link-list {
    margin: 0 0 10px;
  }

  .c-header__link-list:after,
  .c-header__link-list:before {
    content: " ";
    display: table;
  }

  .c-header__link-list:after {
    clear: both;
  }

  .c-header__link-item {
    list-style: none;
    float: left;
    margin-right: 20px;
  }

  .c-header__link-item:last-child {
    margin-right: 0;
  }

  .c-header__link-item:hover {
    text-decoration: underline;
  }

  .c-header__link {
    color: #555;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.5s ease;
  }

  /* Override styles for primary links, per WD-1258. */
  .c-header__link-item--primary {
    .c-header__link,
    .c-header__link:hover,
    .c-header__link:focus {
      color: #ba0046;
    }
  }

  .c-header__link:hover {
    color: #333333;
  }

  .c-header__search-form {
    position: relative;
    max-width: 414px;
  }

  input[type="text"].c-header__search-bar {
    appearance: none;
    border-radius: 4px;
    border: 2px solid #cccccc;
    color: #333333;
    font-size: 16px;
    min-height: 50px;
    padding: 10px 56px 10px 15px;
    width: 100%;

    &::placeholder {
      color: #999999;
    }
  }

  .c-header__search-btn {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 46px;
    width: 56px;
    max-width: 56px;
    background-color: #FFFFFFFFFFFF;
    border: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .c-header__search-btn:active,
  .c-header__search-btn:focus,
  .c-header__search-btn:hover {
    background-color: #ba0046;
  }

  .c-header__search-btn:active > .c-header__search-btn-icon,
  .c-header__search-btn:focus > .c-header__search-btn-icon,
  .c-header__search-btn:hover > .c-header__search-btn-icon {
    background-image: url(https://www.shu.ac.uk/assets/images/icons/magnify-white.png);
  }

  .c-header__search-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    height: 22px;
    width: 22px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/magnify-grey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.5s ease;
  }

  .c-header__bottom-row {
    background-color: #FFFFFFFFFFFF;
    border-bottom: 2px solid #e7e7e7;
  }

  @media only screen and (min-width: 768px) {
    .c-header__bottom-row {
      position: relative;
    }
  }

  .c-header__prospectus {
    float: right;
    display: inline-block;
    vertical-align: middle;
    padding-right: 0;
  }

  .c-header__prospectus:after,
  .c-header__prospectus:before {
    content: " ";
    display: table;
  }

  .c-header__prospectus:after {
    clear: both;
  }

  @media only screen and (max-width: 767px) {
    .c-header__prospectus {
      display: none;
    }
  }

  .c-header__prospectus-link {
    display: block;
    padding: 15px 0 15px 15px;
    color: #555;
    font-size: 0;
    font-weight: 500;
    line-height: 1.71;
  }

  .c-header__prospectus-link:hover .c-header__prospectus-text {
    color: #333;
  }

  @media only screen and (min-width: 768px) and (max-width: 880px) {
    .c-header__prospectus-link {
      padding-top: 19px;
      padding-bottom: 18px;
    }
  }

  .c-header__prospectus-icon {
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    width: 21px;
    margin-right: 7px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/book.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media only screen and (min-width: 1023px) {
    .c-header__prospectus-icon {
      height: 20px;
      width: 24px;
      margin-right: 10px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 880px) {
    .c-header__prospectus-icon {
      height: 20px;
      width: 24px;
      margin-right: 0;
    }
  }

  .c-header__prospectus-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1100px) {
    .c-header__prospectus-text {
      margin-right: 10px;
      font-size: 21px;
    }
  }

  @media only screen and (min-width: 1023px) and (max-width: 1100px) {
    .c-header__prospectus-text {
      margin-right: 10px;
      font-size: 19px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 880px) {
    .c-header__prospectus-text {
      position: absolute !important;
      padding: 0 !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden;
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px);
    }
  }

  .c-header__prospectus-count {
    display: inline-block;
    vertical-align: middle;
    padding: 7px;
    background-color: #ba0046;
    border-radius: 100%;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    line-height: 0.6;
  }

  @media only screen and (min-width: 1023px) {
    .c-header__prospectus-count {
      padding: 9px;
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 880px) {
    .c-header__prospectus-count {
      margin-left: -10px;
      margin-top: -16px;
      padding: 5px;
    }
  }

  .c-header__mobile-search-form {
    position: relative;
  }

  @media only screen and (min-width: 768px) {
    .c-header__mobile-search-form {
      display: none;
    }
  }

  input[type="text"].c-header__mobile-search-bar {
    width: 100%;
    padding: 5px 58px 5px 15px;
    background-color: #f6f6f6;
    border: 0;
    color: #333;
    font-size: 15px;
    line-height: 2;
  }

  input[type="text"].c-header__mobile-search-bar::-webkit-input-placeholder {
    color: #999;
  }

  input[type="text"].c-header__mobile-search-bar::-moz-placeholder {
    color: #999;
  }

  input[type="text"].c-header__mobile-search-bar::-ms-input-placeholder {
    color: #999;
  }

  .c-header__mobile-search-btn {
    position: absolute;
    top: 0;
    right: 2px;
    padding: 11px 20px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .c-header__mobile-search-btn-icon {
    display: block;
    height: 18px;
    width: 18px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/magnify-grey.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media only screen and (max-width: 767px) {
    .c-header .is-hidden-mobile {
      display: none !important;
      visibility: hidden !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .c-header .is-hidden-mobile {
      display: block !important;
      visibility: visible !important;
    }
  }

  .c-nav {
    position: static;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    .c-nav {
      position: fixed;
      top: 0;
      bottom: 0;
      display: block;
      left: 100%;
      width: 100%;
      padding-left: 48px;
      padding-right: 0;
      background-color: #ffffff;
      border-left: 2px solid #e7e7e7;
      z-index: 100;
      transition: left 0.6s ease-in-out;
    }

    .c-nav.is-active {
      left: 0;
    }

    .c-nav.is-invisible {
      width: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    .c-nav.is-invisible {
      visibility: visible;
    }
  }

  .c-nav__close {
    background: 0 0;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 48px;
    border-bottom: 2px solid #e7e7e7;
    border-radius: 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__close {
      display: none;
    }
  }

  .c-nav__close-icon {
    position: absolute;
    top: 18px;
    left: 12px;
    display: block;
    height: 22px;
    width: 22px;
  }

  .c-nav__close-icon:after,
  .c-nav__close-icon:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 3px;
    background-color: #777;
  }

  .c-nav__close-icon:before {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .c-nav__close-icon:after {
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .c-nav__inner {
    position: relative;
    height: 100%;
    padding: 8px 21px 24px;
    background-color: #f6f6f6;
    border-left: 2px solid #e7e7e7;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__inner {
      position: static;
      padding: 0;
      height: auto;
      background-color: #ffffff;
      border: 0;
      overflow: visible;
    }
  }

  .c-nav__top-tier {
    margin: 0;
  }

  .c-nav__top-tier:after,
  .c-nav__top-tier:before {
    content: " ";
    display: table;
  }

  .c-nav__top-tier:after {
    clear: both;
  }

  .c-nav__top-tier-item {
    list-style: none;
    border-bottom: 1px solid #e7e7e7;
  }

  .c-nav__top-tier-item:last-child {
    border-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__top-tier-item {
      float: left;
      border: 0;
    }

    .c-nav__top-tier-item.is-active {
      color: #333;
    }

    .c-nav__top-tier-item.is-active .c-nav__top-tier-link:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      display: block;
      height: 14px;
      width: 36px;
      background-image: url(https://www.shu.ac.uk/assets/images/icons/nav-triangle-down.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 150;
    }

    .c-nav__top-tier-item:first-child .c-nav__top-tier-link {
      padding-left: 0;
    }

    .c-nav__top-tier-item:last-child .c-nav__top-tier-link {
      padding-right: 0;
    }
  }

  .c-nav__second-tier-link,
  .c-nav__second-tier-link--nav,
  .c-nav__third-tier-link,
  .c-nav__top-tier-link {
    position: relative;
    display: block;
    padding: 14px 30px 14px 0;
    font-size: 16px;
    line-height: 1.38;
  }

  .c-nav__top-tier-link {
    color: #555;
  }

  @media only screen and (min-width: 1100px) {
    .c-nav__top-tier-link {
      font-size: 21px;
    }
  }

  @media only screen and (min-width: 1023px) and (max-width: 1100px) {
    .c-nav__top-tier-link {
      font-size: 19px;
    }
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__top-tier-link {
      padding: 15px 12.5px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .c-nav__top-tier-link {
      padding: 15px 10px;
    }
  }

  @media only screen and (min-width: 768px) {
    .c-nav__top-tier-link {
      position: relative;
      display: block;
      font-weight: 500;
      line-height: 1.71;
    }
  }

  .c-nav__skip-link {
    position: absolute;
    top: 100%;
    display: block;
    padding: 5px 8px;
    background-color: #ffffff;
    border: 2px solid #e7e7e7;
    color: #ba0046;
    font-size: 21px;
    font-weight: 500;
    opacity: 0;
    z-index: 200;
    pointer-events: none;
  }

  .c-nav__skip-link:focus {
    opacity: 1;
    pointer-events: all;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__skip-link {
      display: none;
    }
  }

  .c-nav__mega-menu {
    position: absolute;
    z-index: 100;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__mega-menu {
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: #f6f6f6;
      border-left: 2px solid #e7e7e7;
      z-index: 100;
      transition: left 0.6s ease-in-out;
      box-sizing: content-box;
    }

    .c-nav__top-tier-item--nav.is-active .c-nav__mega-menu {
      left: -2px;
    }

    .c-nav__mega-menu .row {
      width: 100%;
      margin: 0;
    }

    .c-nav__mega-menu * {
      box-sizing: border-box;
    }
  }

  @media only screen and (min-width: 768px) {
    .c-nav__mega-menu {
      left: 0;
      right: 0;
      display: none;
      height: auto;
      background-color: #ffffff;
      border-top: 2px solid #e7e7e7;
      border-bottom: 2px solid #e7e7e7;
    }

    .c-nav__mega-menu:before {
      content: " ";
      position: absolute;
      display: block;
      background-color: #ffffff;
      height: 100%;
      width: 50%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .c-nav__mega-menu:after {
      content: " ";
      position: absolute;
      display: block;
      background-color: #f6f6f6;
      height: 100%;
      width: 50%;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .c-nav__top-tier-item--nav.is-active .c-nav__mega-menu {
      display: block;
    }

    .c-nav__mega-menu.is-invisible {
      visibility: visible;
    }

    .c-nav__mega-menu > .row {
      max-width: 1120px;
      margin: 0 auto;
    }
  }

  .c-nav__mega-menu-inner.columns {
    position: static;
    padding-left: 0;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .c-nav__mega-menu-inner.columns {
      padding-right: 25px;
    }
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__mega-menu-inner.columns {
      position: relative;
      z-index: 2;
    }
  }

  .c-nav__current-page {
    margin-bottom: 0;
    padding: 20px 21px 16px 21px;
    background-color: #ffffff;
    border-bottom: 2px solid #e7e7e7;
    color: #ba0046;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__current-page {
      display: none;
    }
  }

  .c-nav__second-tier.columns {
    margin: 0;
    padding: 0 21px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .c-nav__second-tier.columns {
      padding: 20px 0 20px 25px;
      z-index: 3;
    }
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__second-tier.columns {
      padding: 30px 0 30px 15px;
      z-index: 3;
    }
  }

  .c-nav__second-tier-item {
    list-style: none;
    border-bottom: 1px solid #e7e7e7;
  }

  .c-nav__second-tier-item:last-child {
    border-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__second-tier-item {
      border: 0;
    }
  }

  .c-nav__second-tier-link,
  .c-nav__second-tier-link--nav {
    color: #555;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__second-tier-link,
    .c-nav__second-tier-link--nav {
      position: relative;
      display: block;
      padding: 6px 16px 6px 0;
      color: #555;
      font-weight: 500;
      word-wrap: break-word;
    }

    .c-nav__second-tier-link.is-active,
    .is-active.c-nav__second-tier-link--nav {
      color: #333;
    }
  }

  @media only screen and (min-width: 1100px) {
    .c-nav__second-tier-link,
    .c-nav__second-tier-link--nav {
      font-size: 20px !important;
    }
  }

  @media only screen and (min-width: 1023px) and (max-width: 1100px) {
    .c-nav__second-tier-link,
    .c-nav__second-tier-link--nav {
      font-size: 19px;
    }
  }

  .c-nav__second-tier-link:focus,
  .c-nav__second-tier-link:hover {
    color: #b70d50;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__second-tier-link--nav.is-active:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      display: block;
      height: 36px;
      width: 14px;
      background-image: url(https://www.shu.ac.uk/assets/images/icons/nav-triangle-right.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 3;
    }
  }

  @media only screen and (min-width: 1100px) {
    .c-nav__second-tier-link--nav {
      font-size: 20px !important;
    }
  }

  @media only screen and (min-width: 1023px) and (max-width: 1100px) {
    .c-nav__second-tier-link--nav {
      font-size: 18px;
    }
  }

  .c-nav__jump-link {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    color: #ba0046;
    font-size: 21px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
  }

  .c-nav__jump-link:focus {
    opacity: 1;
    pointer-events: all;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__jump-link {
      display: block;
    }
  }

  @media only screen and (min-width: 1100px) {
    .c-nav__jump-link {
      font-size: 21px;
    }
  }

  @media only screen and (min-width: 1023px) and (max-width: 1100px) {
    .c-nav__jump-link {
      font-size: 19px;
    }
  }

  .c-nav__third-tier-wrapper--2-col,
  .c-nav__third-tier-wrapper--3-col {
    padding: 0 0 30px;
    min-height: 310px;
    background-color: #f6f6f6;
    z-index: 2;
  }

  .c-nav__third-tier-wrapper--2-col:after,
  .c-nav__third-tier-wrapper--2-col:before,
  .c-nav__third-tier-wrapper--3-col:after,
  .c-nav__third-tier-wrapper--3-col:before {
    content: " ";
    display: table;
  }

  .c-nav__third-tier-wrapper--2-col:after,
  .c-nav__third-tier-wrapper--3-col:after {
    clear: both;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__third-tier-wrapper--2-col,
    .c-nav__third-tier-wrapper--3-col {
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      height: 100%;
      width: 100%;
      border-left: 2px solid #e7e7e7;
      transition: left 0.6s ease-in-out;
    }

    .is-active.c-nav__third-tier-wrapper--2-col,
    .is-active.c-nav__third-tier-wrapper--3-col {
      left: -2px;
    }
  }

  @media only screen and (min-width: 768px) {
    .c-nav__third-tier-wrapper--2-col,
    .c-nav__third-tier-wrapper--3-col {
      position: relative;
      display: none;
      border-left: 2px solid #e7e7e7;
    }

    .is-active.c-nav__third-tier-wrapper--2-col,
    .is-active.c-nav__third-tier-wrapper--3-col {
      display: block;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .c-nav__third-tier-wrapper--2-col {
      padding: 22px 0 43px 15px;
    }
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__third-tier-wrapper--2-col {
      padding: 35px 0 43px 40px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .c-nav__third-tier-wrapper--3-col {
      padding: 22px 0 43px 15px;
    }
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__third-tier-wrapper--3-col {
      padding: 35px 0 43px 40px;
    }
  }

  .c-nav__third-tier-heading {
    display: block;
    margin-bottom: 8px;
    padding-bottom: 10px;
    color: #020202;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 2px solid #e7e7e7;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__third-tier-heading {
      display: none;
    }
  }

  .c-nav__third-tier {
    margin: 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__third-tier {
      padding-top: 0;
    }
  }

  .c-nav__third-tier-item {
    list-style: none;
    border-bottom: 1px solid #e7e7e7;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__third-tier-item {
      border: 0;
    }
  }

  .c-nav__third-tier-link {
    color: #555;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__third-tier-link {
      display: block;
      padding: 8px 0;
      font-size: 16px;
      line-height: 1.44;
    }

    .c-nav__third-tier-link:hover {
      color: #333;
      text-decoration: underline !important;
    }
  }

  @media only screen and (min-width: 1024px) {
    .c-nav__third-tier-link {
      font-size: 18px;
    }
  }

  .c-nav__third-tier-panel {
    position: absolute;
    top: 0;
    left: 100%;
    width: 15px;
    height: 100%;
    background-color: #f6f6f6;
  }

  @media only screen and (min-width: 1023px) {
    .c-nav__third-tier-panel {
      width: 120px;
    }
  }

  .c-nav__cta-wrapper.columns {
    padding-right: 0;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__cta-wrapper.columns {
      display: none;
    }
  }

  .c-header__search-bar,
  .c-header__search-btn {
    background: white;
  }

  .c-nav__cta {
    position: relative;
    border-radius: 6px;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.15);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .c-nav__cta-link {
    display: block;
    padding: 17px 19px;
    background-color: #ffffff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #555;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.24;
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .c-nav__cta-link:hover {
    color: #333;
  }

  .c-nav__cta-link:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .c-nav__cta-link + .c-nav__cta-image {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-nav__cta-image {
    display: block;
    width: 100%;
    border-radius: 6px;
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .c-nav__return-link {
    position: absolute;
    bottom: 15px;
    left: 55px;
    display: none;
    color: #ba0046;
    font-size: 18px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
  }

  .c-nav__return-link:focus {
    opacity: 1;
    pointer-events: all;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__return-link {
      display: block;
    }
  }

  .c-nav__link-wrapper {
    position: relative;
  }

  .c-nav__btn {
    background: 0 0;
    border: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__btn {
      display: none;
    }
  }

  .c-nav__btn-icon {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    height: 24px;
    width: 14px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/nav-arrow-right.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .c-nav__back-btn {
    background: 0 0;
    border: none;
    padding: 0;
    margin: 21px 0 21px 21px;
    border-radius: 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__back-btn {
      display: none;
    }
  }

  .c-nav__back-btn-icon {
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 14px;
    margin-right: 5px;
    background-image: url(https://www.shu.ac.uk/assets/images/icons/nav-arrow-left.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .c-nav__back-btn-text {
    display: inline-block;
    vertical-align: middle;
    color: #ba0046;
    font-size: 16px;
    line-height: 1.38;
  }

  .c-nav__link-list {
    margin: 30px 0 0;
  }

  @media only screen and (min-width: 768px) {
    .c-nav__link-list {
      display: none;
    }
  }

  .c-nav__link-item {
    list-style: none;
  }

  .c-nav__link {
    color: #a8a8a8;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.87;
  }

  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    transition: all 0.3s linear;
    border-radius: 6px;
    display: inline-block;
    padding: 12px 26.4px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-transform: default;
    color: #ffffff;
    background-color: #b70d50;
    background-clip: border-box;
    border: 2px solid #b70d50;
    text-decoration: none;
    text-align: center;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  button[disabled] {
    background: #eeeeee !important;
    border: 2px solid #eeeeee !important;
    color: #ffffff !important;
    cursor: not-allowed !important;
    border-color: #eeeeee !important;
  }

  .label {
    margin-right: 10px;
  }

  .label:last-child {
    margin: 0;
  }

  .media img {
    width: 100%;
    height: auto;
    z-index: 11;
  }

  .media figcaption {
    font-style: italic;
    font-weight: 400;
    margin-top: 5px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 28px;
    background-size: 18px;
    background-repeat: no-repeat;
  }

  @media only screen and (min-width: 768px) {
    .media figcaption {
      margin-top: 10px;
      font-size: 18px;
      line-height: 26px;
      background-size: 24px;
      padding-left: 34px;
    }
  }

  .section.form .large-10 form .row {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    .c-nav__top-tier-item--nav.is-active .c-nav__mega-menu {
      padding-bottom: 100vh;
    }
  }

  @media only screen and (min-width: 415px) and (max-width: 767px) {
    .c-nav__third-tier-wrapper--2-col,
    .c-nav__third-tier-wrapper--3-col {
      height: 200vh !important;
      background-color: #f6f6f6;
    }

    .c-nav__mega-menu {
      height: 200vh !important;
    }
  }

  body,
  html {
    height: 100%;
  }

  *,
  :after,
  :before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  body,
  html {
    font-size: 100%;
  }

  a:hover {
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img {
    -ms-interpolation-mode: bicubic;
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }

  .row {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    // max-width: 970px
  }

  .row:after,
  .row:before {
    content: " ";
    display: table;
  }

  .row:after {
    clear: both;
  }

  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0;
    float: left;
  }

  .row.collapse .row {
    margin-left: 0;
    margin-right: 0;
  }

  .row .row {
    // width: auto;
    // margin-left: -.9375rem;
    // margin-right: -.9375rem;
    // margin-top: 0;
    // margin-bottom: 0;
    // max-width: none
  }

  .row .row:after,
  .row .row:before {
    content: " ";
    display: table;
  }

  .row .row:after {
    clear: both;
  }

  .row .row.collapse {
    width: auto;
    margin: 0;
    max-width: none;
  }

  .row .row.collapse:after,
  .row .row.collapse:before {
    content: " ";
    display: table;
  }

  .row .row.collapse:after {
    clear: both;
  }

  .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    width: 100%;
    float: left;
  }

  @media only screen {
    .columns {
      position: relative;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      float: left;
    }

    [class*="column"] + [class*="column"]:last-child {
      float: right;
    }
  }

  @media only screen and (min-width: 48em) {
    .columns {
      position: relative;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      float: left;
    }

    .medium-3 {
      width: 25%;
    }

    .medium-4 {
      width: 33.33333%;
    }

    .medium-6 {
      width: 50%;
    }

    .medium-9 {
      width: 75%;
    }

    [class*="column"] + [class*="column"]:last-child {
      float: right;
    }
  }

  @media only screen and (min-width: 64.063em) {
    .columns {
      position: relative;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      float: left;
    }

    .large-2 {
      width: 16.66667%;
    }

    .large-10 {
      width: 83.33333%;
    }

    .large-12 {
      width: 100%;
    }

    [class*="column"] + [class*="column"]:last-child {
      float: right;
    }
  }

  @-webkit-keyframes webkitSiblingBugfix {
    from,
    to {
      position: relative;
    }
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-o-keyframes rotate {
    from {
      -o-transform: rotate(0);
    }

    to {
      -o-transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  [data-magellan-expedition] {
    background: #ffffff;
    z-index: 50;
    min-width: 100%;
    padding: 10px;
  }

  div,
  form,
  li,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    line-height: inherit;
  }

  a img {
    border: none;
  }

  strong {
    font-weight: 700;
    line-height: inherit;
  }

  ul {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;
    list-style-position: outside;
    font-family: inherit;
  }

  ul {
    margin-left: 1rem;
  }

  ul li ul {
    margin-left: 1.25rem;
    margin-bottom: 0;
  }

  acronym {
    text-transform: uppercase;
    font-size: 90%;
    color: #222;
    border-bottom: 1px dotted #dddddd;
    cursor: help;
  }

  @media print {
    * {
      background: 0 0 !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    @page {
      margin: 0.5cm;
    }

    p {
      orphans: 3;
      widows: 3;
    }
  }

  [data-clearing] {
    margin-bottom: 0;
    margin-left: 0;
    list-style: none;
  }

  [data-clearing]:after,
  [data-clearing]:before {
    content: " ";
    display: table;
  }

  [data-clearing]:after {
    clear: both;
  }

  [data-clearing] li {
    float: left;
    margin-right: 10px;
  }

  [data-clearing][class*="block-grid-"] li {
    margin-right: 0;
  }

  @media only screen and (min-width: 40.063em) {
    [class*="block-grid-"] {
      display: block;
      padding: 0;
      margin: 0 -0.625rem;
    }

    [class*="block-grid-"]:after,
    [class*="block-grid-"]:before {
      content: " ";
      display: table;
    }

    [class*="block-grid-"]:after {
      clear: both;
    }

    [class*="block-grid-"] > li {
      display: block;
      height: auto;
      float: left;
      padding: 0 0.625rem 1.25rem;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
